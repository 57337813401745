import React, { lazy, Suspense, useEffect } from "react";
import {
  Route,
  Redirect,
  BrowserRouter as Router,
  Switch,
  useLocation,
} from "react-router-dom";
import { useSelector } from "react-redux";

import ErrorBoundary from "./ErrorBoundary";
import { PUBLIC_ROUTE } from "./route.constants";
import Loader from "@iso/components/utility/loader";

const Dashboard = lazy(() => import("./containers/Dashboard/Dashboard"));

const publicRoutes = [
  {
    path: PUBLIC_ROUTE.LANDING,
    exact: true,
    component: lazy(() => import("@iso/containers/Pages/SignIn/SignIn")),
  },
  {
    path: PUBLIC_ROUTE.PAGE_404,
    component: lazy(() => import("@iso/containers/Pages/404/404")),
  },
  {
    path: PUBLIC_ROUTE.PAGE_500,
    component: lazy(() => import("@iso/containers/Pages/500/500")),
  },
  {
    path: PUBLIC_ROUTE.SIGN_IN,
    component: lazy(() => import("@iso/containers/Pages/SignIn/SignIn")),
  },
  {
    path: PUBLIC_ROUTE.SIGN_UP,
    component: lazy(() => import("@iso/containers/Pages/SignUp/SignUp")),
  },
  {
    path: PUBLIC_ROUTE.FORGET_PASSWORD,
    component: lazy(() =>
      import("@iso/containers/Pages/ForgotPassword/ForgotPassword")
    ),
  },
  {
    path: PUBLIC_ROUTE.RESET_PASSWORD,
    component: lazy(() =>
      import("@iso/containers/Pages/ResetPassword/ResetPassword")
    ),
  },
  {
    path: PUBLIC_ROUTE.CREATE_PASSWORD,
    component: lazy(() =>
      import("@iso/containers/Pages/CreatePassword/CreatePassword")
    ),
  },
  // {
  //   path: PUBLIC_ROUTE.AUTH0_CALLBACK,
  //   component: lazy(() =>
  //     import('@iso/containers/Authentication/Auth0/Auth0Callback')
  //   ),
  // },
];

function PrivateRoute({ children, ...rest }) {
  let location = useLocation();
  const isLoggedIn = useSelector((state) => state.Auth.idToken);

  const token = useSelector((state) => state.Auth.idToken);

  // Chatbot script
  useEffect(() => {
    // Function to append chatbot scripts
    const appendChatbotScripts = () => {
      // Create the script element for the chatbot config
      const scriptConfig = document.createElement("script");
      scriptConfig.innerHTML = `window.embeddedChatbotConfig = {
        chatbotId: "VBJMOleAMc0YGYqr6wc7i",
        domain: "www.chatbase.co"
      }`;
      document.body.appendChild(scriptConfig);

      // Create the script element to load the chatbot
      const scriptChatbot = document.createElement("script");
      scriptChatbot.src = "https://www.chatbase.co/embed.min.js";
      scriptChatbot.setAttribute("chatbotId", "VBJMOleAMc0YGYqr6wc7i");
      scriptChatbot.setAttribute("domain", "www.chatbase.co");
      scriptChatbot.defer = false;
      document.body.appendChild(scriptChatbot);

      return { scriptConfig, scriptChatbot }; // Return scripts for cleanup
    };

    // Append scripts if token exists
    let chatbotScripts;
    // if (token) {
    chatbotScripts = appendChatbotScripts();
    // }

    // Cleanup function to remove the scripts when the component unmounts or token changes
    return () => {
      if (chatbotScripts) {
        // const scriptConfig = document.getElementById("chatbase-bubble-button");
        // console.log("🚀 ~ return ~ scriptConfig:", scriptConfig);
        // if (scriptConfig) {
        //   scriptConfig.remove();
        // }
        // Remove scriptConfig from body
        if (document.body.contains(chatbotScripts.scriptConfig)) {
          document.body.removeChild(chatbotScripts.scriptConfig);
        }

        // Remove scriptChatbot from body
        if (document.body.contains(chatbotScripts.scriptChatbot)) {
          document.body.removeChild(chatbotScripts.scriptChatbot);
        }
      }
    };
  }, [isLoggedIn]);

  if (isLoggedIn) return <Route {...rest}>{children}</Route>;
  return (
    <Redirect
      to={{
        pathname: "/signin",
        state: { from: location },
      }}
    />
  );
}
export default function Routes() {
  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
        <Router>
          <Switch>
            {publicRoutes.map((route, index) => (
              <Route key={index} path={route.path} exact={route.exact}>
                <route.component />
              </Route>
            ))}
            <PrivateRoute path="/dashboard">
              <Dashboard />
            </PrivateRoute>
          </Switch>
        </Router>
      </Suspense>
    </ErrorBoundary>
  );
}
