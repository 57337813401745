const options = [
  {
    key: 'calendar',
    userType: ['admin', 'therapie', 'patient'],
    label: 'sidebar.calendar',
    leftIcon: 'ion-calendar',
    img: 'calendar',
  },
  {
    key: 'appointments',
    userType: ['admin', 'therapie', 'patient'],
    label: 'sidebar.Appointments',
    leftIcon: 'ion-calendar',
    img: 'appointment',
  },
  {
    key: 'chat-admin',
    userType: ['admin'],
    label: 'sidebar.chat',
    leftIcon: 'ion-chatbubbles',
    img: 'chat',
    children: [
      {
        key: 'chat',
        userType: ['therapie', 'patient'],
        label: 'My Chat',
      },
      {
        key: 'all-chats',
        userType: ['admin'],
        label: 'All Chat',
      },
    ],
  },
  {
    key: 'chat',
    userType: ['therapie', 'patient'],
    label: 'sidebar.chat',
    leftIcon: 'ion-chatbubbles',
    img: 'chat',
  },
  {
    key: 'my-therapist',
    userType: ['patient'],
    label: 'My Therapist',
    leftIcon: 'ion-person',
    img: 'therapist',
  },
  {
    key: 'therapist',
    userType: ['admin'],
    label: 'sidebar.Therapist',
    leftIcon: 'ion-android-contacts',
    img: 'therapist',
  },
  {
    key: 'patient',
    userType: ['admin'],
    label: 'sidebar.Patients',
    leftIcon: 'ion-person',
    img: 'patient',
  },
  {
    key: 'file-manager',
    userType: ['admin', 'therapie', 'patient'],
    label: 'sidebar.FileManager',
    leftIcon: 'ion-document',
    img: 'file-manager',
  },
  {
    key: 'my-protocols',
    label: 'My Protocols',
    leftIcon: 'ion-chatboxes',
    userType: ['therapie'],
    img: 'protocol',
  },
  {
    key: 'manage-protocols',
    label: 'Manage Protocols',
    userType: ['admin'],
    leftIcon: 'ion-aperture',
    img: 'protocol',
    children: [
      {
        key: 'all-protocols',
        label: 'sidebar.allProtocols',
      },
      {
        key: 'implementations',
        label: 'sidebar.implementations',
      },
      {
        key: 'patient-conditions',
        label: 'sidebar.patientConditions',
      },
    ],
  },
  {
    key: 'all-feedback',
    label: 'All Feedback',
    leftIcon: 'ion-chatboxes',
    userType: ['admin'],
    img: 'feedback',
  },
  {
    key: 'my-feedback',
    label: 'My Feedback',
    leftIcon: 'ion-chatboxes',
    userType: ['patient'],
    img: 'feedback',
  },
  {
    key: 'invoices',
    label: 'sidebar.invoice',
    leftIcon: 'ion-clipboard',
    userType: ['admin'],
    img: 'Invoice',
  },
  {
    key: 'my-invoices',
    label: 'sidebar.invoice',
    leftIcon: 'ion-clipboard',
    userType: ['patient'],
    img: 'Invoice',
  },
  {
    key: 'email-template',
    userType: ['admin'],
    label: 'sidebar.Emails',
    leftIcon: 'ion-email',
    img: 'Email',
  },
  {
    key: 'blogs',
    userType: ['admin'],
    label: 'sidebar.Blogs',
    leftIcon: 'ion-document-text',
    img: 'blog',
  },
  {
    key: 'services',
    userType: ['admin'],
    label: 'sidebar.Services',
    leftIcon: 'ion-settings',
    img: 'services',
  },
  {
    key: 'cms',
    userType: ['admin'],
    label: 'sidebar.Cms',
    leftIcon: 'ion-document-text',
    img: 'cms',
  },
  {
    key: 'manage-admin',
    userType: ['admin'],
    label: 'sidebar.ManageAdmin',
    leftIcon: 'ion-android-contacts',
    img: 'admin',
  },
  {
    key: 'languages mgt',
    userType: ['admin'],
    label: 'sidebar.Languages',
    leftIcon: 'ion-android-globe',
    children: [
      {
        key: 'languages',
        label: 'sidebar.Languages',
      },
      {
        key: 'all-words',
        label: 'sidebar.AllWords',
      },
    ],
  },
  {
    key: 'settings',
    userType: ['admin'],
    label: 'themeSwitcher.settings',
    leftIcon: 'ion-settings',
    img: 'settings',
  },
  {
    key: 'delete-requests',
    label: 'Delete Requests',
    leftIcon: 'ion-document',
    userType: ['admin'],
    img: 'protocol',
  },
  {
    key: 'legal',
    userType: ['therapie', 'patient'],
    label: 'Legal',
    leftIcon: 'ion-android-globe',
    img: 'file-manager',
    children: [
      {
        type: 'frontend',
        key: 'impressum',
        label: 'Imprint',
      },
      {
        key: 'datenschutz',
        type: 'frontend',
        label: 'Data Protection',
      },
      {
        key: 'allgemeine-geschaeftsbedingungen',
        type: 'frontend',
        label: 'Terms of Service',
      },
    ],
  },
  {
    key: 'insurance-management',
    userType: ['admin'],
    label: 'sidebar.Insurance',
    leftIcon: 'ion-email',
    img: 'insurance',
    children: [
      {
        key: 'insurance',
        label: 'sidebar.TypeManagement',
        userType: ['admin'],
      },
      {
        key: 'insurance-type',
        label: 'sidebar.InsuranceManagement',
        userType: ['admin'],
      },
    ],
  },
  {
    key: 'insurance-request',
    label: 'sidebar.insurancerequest',
    img: 'insurance',
    userType: ['admin'],
  },
  {
    key: 'location',
    label: 'sidebar.location',
    img: 'location',
    userType: ['admin'],
  },
  {
    key: 'manage-cities',
    label: 'sidebar.managecity',
    img: 'location',
    userType: ['admin'],
  },
  {
    key: 'career',
    label: 'career.title',
    img: 'suitcase',
    userType: ['admin'],
  },
  // {
  //   key: 'area-management',
  //   userType: ['admin'],
  //   label: 'sidebar.area',
  //   leftIcon: 'ion-email',
  //   img: 'location',
  //   children: [
  //     {
  //       key: 'district',
  //       label: 'sidebar.district',
  //       userType: ['admin'],
  //     },
  //     {
  //       key: 'city',
  //       label: 'sidebar.city',
  //       userType: ['admin'],
  //     },
  //   ],
  // },
];
export default options;
