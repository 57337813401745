import antdSA from 'antd/lib/locale-provider/de_DE';
import appLocaleData from 'react-intl/locale-data/de';
import saMessages from '../locales/de_DE.json';

const deLang = {
  messages: {
    ...saMessages,
  },
  antd: antdSA,
  locale: 'de',
  data: appLocaleData,
};
export default deLang;
