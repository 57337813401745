import { all, takeEvery, put, fork } from 'redux-saga/effects';
import { createBrowserHistory } from 'history';
import { isObject, isString } from 'lodash';

import { getToken, clearToken, getUserData } from '@iso/lib/helpers/utility';
import actions from './actions';

const history = createBrowserHistory();
// const fakeApiCall = true; // auth0 or express JWT

export function* loginRequest() {
  yield takeEvery('LOGIN_REQUEST', function*({ payload }) {
    const { token, user } = payload;
    if (token) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        token: token,
        user: user,
        profile: 'Profile',
      });
    } else {
        yield put({ type: actions.LOGIN_ERROR });
    }
  });
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function*(payload) {
    const user = isObject(payload.user) ? JSON.stringify(payload.user) : payload.user;
    const userLocal = user ? user : '';
    const token = isString(payload.token) ? payload.token : '';
    yield localStorage.setItem('id_token', token);
    yield localStorage.setItem('user_data', userLocal);
  });
}

export function* setUser() {
  yield takeEvery('SET_USER', function*(payload) {
    const user = isObject(payload.user) ? JSON.stringify(payload.user) : payload.user;
    const userLocal = user ? user : '';
    const token = payload.token || '';
    localStorage.setItem('user_data', userLocal);
    yield put({
      type: actions.LOGIN_SUCCESS,
      user: payload.user,
      token,
    });
  });
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function*() {});
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function*() {
    yield clearToken();
    history.push('/');
  });
}
export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function*() {
    const token = getToken().get('idToken');
    const user = getUserData().get('userData');
    if (token) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        token,
        user: user,
        profile: 'Profile',
      });
    }
  });
}
export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(loginRequest),
    fork(loginSuccess),
    fork(loginError),
    fork(logout),
    fork(setUser),
  ]);
}
