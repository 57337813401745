import { notification } from 'antd';
import { has, isObject } from 'lodash';
import { store } from '@iso/redux/store';
const createNotification = (type, message, description) => {
	const state = store ? store.getState() : {};
  const allWords = isObject(state) && has(state, 'LanguageSwitcher.allWords') ? state.LanguageSwitcher.allWords : {};
  const transMsg = isObject(allWords) && allWords[message] ? allWords[message] : message;
  // console.log('transMsg=======>');
  // console.log(transMsg);
  notification[type]({
    message: transMsg,
    description
  });

};
export default createNotification;
